import { FormattedHTMLMessage, useIntl } from "gatsby-plugin-intl";
import React, { useEffect, useRef, useState } from "react";

import providedBy from "../../images/homecredit/PH/provided-by-bolttech.png";
import providedByID from "../../images/homecreditID/ID/providedby_ID.png";
import { GTM_TAGS, OTHER_REQ_OPTIONS, PARTNER_COUNTRY, PARTNER_DETAILS } from "../../utils/constants";
import { gtmEventTrack } from "../../utils/utilities";
import ModalPopup from "../modal";
import whatsappIcon from "../../images/homecreditID/ID/whatsapp.svg";
import wa_QR_ID_uat from "../../images/homecreditID/ID/qr_code_wa_chat_staging.png";
import wa_QR_ID_prod from "../../images/homecreditID/ID/qr_code_wa_chat_prod.png";

import "./banner.scss";

function Banner() {
  const [languageParam, setLanguageParam] = useState('EN');
  const bnrWrap = useRef(null);
  const stickyBnrWrap = useRef(null);
  const intl = useIntl();
  const bannerMobile = require(`../../images/${process.env.GATSBY_PARTNER}/${process.env.GATSBY_COUNTRY}/banner-mobile.png`);
  const bannerTablet = require(`../../images/${process.env.GATSBY_PARTNER}/${process.env.GATSBY_COUNTRY}/banner-tablet.png`);
  const bannerDesktop = require(`../../images/${process.env.GATSBY_PARTNER}/${process.env.GATSBY_COUNTRY}/banner.png`);
  const isMyBnm = process.env.GATSBY_PARTNER === PARTNER_DETAILS.BOLTTECH && ((process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MY) || (process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MYDIGI))
  const isHKELECBOY = process.env.GATSBY_PARTNER === PARTNER_DETAILS.ELECBOY && (process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.HK);
  const isSINGTEL = process.env.GATSBY_PARTNER === PARTNER_DETAILS.SINGTEL && (process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.SG);
  const isTre = process.env.GATSBY_PARTNER === PARTNER_DETAILS.TRE && (process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.SE);
  const isEPIC = process.env.GATSBY_PARTNER === PARTNER_DETAILS.EPIC;

  const navigateToPortal = () => {
    if (
      GTM_TAGS[process.env.GATSBY_PARTNER] &&
      GTM_TAGS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY] &&
      GTM_TAGS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY]
        .SERVICE_PORTAL
    ) {

      gtmEventTrack({
        ...GTM_TAGS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY]
          .SERVICE_PORTAL.CLICK,
        action: "Click",
      });
    }
  };
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = (GTMTrackType, device) => {
    if (!device) {
      setModalOpen(!modalOpen);
    }
    gtmEventTrack({
      ...GTM_TAGS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY][
        GTMTrackType
      ].CLICK,
      action: "Click",
    });
  };
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const observer = new IntersectionObserver(
        ([e]) => {
          if (e.intersectionRatio * 100 === 0) {
            stickyBnrWrap.current.classList.add("block");
            stickyBnrWrap.current.classList.remove("hidden");
          } else {
            stickyBnrWrap.current.classList.remove("block");
            stickyBnrWrap.current.classList.add("hidden");
          }
        },
        { threshold: [0], rootMargin: "-82px 0px 0px 0px" }
      );

      observer.observe(bnrWrap.current);
    }
    setLanguageParam(intl.locale.toUpperCase())
    setShowPopup(OTHER_REQ_OPTIONS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY].SHOW_POPUP)
  }, []);

  useEffect(() => {
    setLanguageParam(intl.locale.toUpperCase())
  }, [intl.locale]);

  const scrollSmoothTo = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
    }
  }

  const closeModal = () => {
    setShowPopup(false)
  };

  const getWAQR = () => {
    if (process.env.GATSBY_ENV === "uat") { 
      return wa_QR_ID_uat;  
    }
    return wa_QR_ID_prod;
  };

  // const waPopup = () => {
  //   return <>
  //     <div className="display-none display-desktop">
  //       <p className="link-txt">
  //         <a rel="noreferrer" className="chat-via-viber" onClick={() => toggleModal('VIBER')} href="#!">
  //           {intl.formatMessage({ id: "msgViaWhatsUp" })}
  //         </a>
  //       </p>
  //       {modalOpen && (
  //         <div className="custom-modal" id="chatViaViberModel">
  //           <div className="text-center">
  //             <div className="close-modal" onClick={() => setModalOpen(!modalOpen)}>
  //               <img
  //                 src={require("../../images/cross_icon.svg")}
  //                 alt="Close"
  //               />
  //             </div>
  //             <p className="font-medium">
  //               <FormattedHTMLMessage id="viberScanHeading" />
  //             </p>
  //             <img src={getWAQR()} alt="ViberQR" />
  //             <p>
  //               <FormattedHTMLMessage id="viberScanBody" />
  //             </p>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //     <div className="display-none display-mobile display-tablet">
  //       <p className="link-txt">
  //         <a
  //           rel="noreferrer"
  //           target="_blank"
  //           className="chat-via-viber"
  //           onClick={() => toggleModal('VIBER', 'mobile')}
  //           href={process.env.GATSBY_VIBER_URL}
  //         >
  //           {intl.formatMessage({ id: "msgViaWhatsUp" })}
  //         </a>
  //       </p>
  //     </div>
  //   </>
  // };

  return (
    <>
      <ModalPopup
        hideCloseBtn={false}
        show={showPopup}
        size="md"
        heading={intl.formatMessage({ id: "popup_heading" })}
        onClose={() => closeModal()}
      >
        <div className="modalBorder" />
        <div>
          <div>
            <div className="mt-4"><FormattedHTMLMessage id="popup_subheading" /></div>
            <br />
            <div>{intl.formatMessage({ id: "popup_subheading1" })}</div>
            <ul className='center-align'>
              <li><FormattedHTMLMessage id="popup_list_item1" /></li>
              <li><FormattedHTMLMessage id="popup_list_item2" /></li>
              <li><FormattedHTMLMessage id="popup_list_item3" /></li>
            </ul>
          </div>
          <div className="modalBorder" />
          <div className='modalFooter'>
            <span></span>
            <a
              type="button"
              className="portal-btn btn-space visit-portal"
              onClick={() => closeModal()}
            >
              {intl.formatMessage({ id: "popup_button" })}
            </a>
          </div>
        </div>
      </ModalPopup>
      <section ref={bnrWrap}>
        <div className="home-banner">
          <img
            src={bannerMobile}
            className="display-none display-mobile"
            alt="Bolttech"
          />
          <img
            src={bannerTablet}
            className="display-none display-tablet"
            alt="Bolttech"
          />
          <img
            src={bannerDesktop}
            className="display-none display-desktop"
            alt="Bolttech"
          />
          {(process.env.GATSBY_PARTNER !== PARTNER_DETAILS.EIR && !isSINGTEL && !isTre) ?
          <div className="banner-content main-container">
            <div data-track-btn="sPortal" className='banner-heading-container'>
              <span data-track-btn="sPortal" className="banner-heading">
                <FormattedHTMLMessage id="bannerHeading" />
              </span>
              <a
                type="button"
                id="navToPortal"
                data-track-btn="sPortal"
                className="portal-btn sp-btn-primary btn-space visit-portal"
                onClick={navigateToPortal}
                href={`${process.env.GATSBY_CLAIMS_PAGE_LINK}&language=${languageParam}`}
                rel="noreferrer"
                target="_blank"
              >
                {intl.formatMessage({ id: "portalBtn" })}
                {!isEPIC && <svg width="14" height="14" viewBox="0 0 7 14">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.284 14L0 12.716 5.716 7 0 1.284 1.284 0l7 7-7 7z"
                  />
                </svg>}
                </a>
                {process.env.GATSBY_PARTNER === PARTNER_DETAILS.HOMECREDIT_ID && (<a
                  type="button"
                  id="navToWA"
                  className="portal-btn btn-space visit-portal sp-btn-primary-wa"
                  onClick={() => toggleModal('VIBER')}
                  href='javascript:void(0)'
                >
                  {intl.formatMessage({ id: "msgViaWhatsUp" })}
                  <img className="wa-icon" src={whatsappIcon} alt="Whastapp" />
                </a>
                )}
                {modalOpen && (
                  <div className="custom-modal show" id="chatViaViberModel">
                    <div className="chat-via-viber-scan text-center">
                      <div className="close-modal" onClick={() => setModalOpen(!modalOpen)}>
                        <img
                          src={require("../../images/homecreditID/ID/cross_icon.svg")}
                          alt="Close"
                        />
                      </div>
                      <p className="font-medium">
                        <FormattedHTMLMessage id="viberScanHeading" />
                      </p>
                      <img src={getWAQR()} alt="ViberQR" />
                      <p>
                        <FormattedHTMLMessage id="viberScanBody" />
                      </p>
                    </div>
                  </div>
                )}
              </div>
              
            {!isMyBnm && !isHKELECBOY && !isEPIC && (
              <button className="faq-btn" onClick={() => scrollSmoothTo('faqs')}>
                {intl.formatMessage({ id: "viewFaqs" })}
                <svg width="14" height="14" viewBox="0 0 7 14">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.284 14L0 12.716 5.716 7 0 1.284 1.284 0l7 7-7 7z"
                  />
                </svg>
              </button>
            )}
            
          </div>
          :
          <div className="banner-content main-container">
            {!isSINGTEL && 
            <div>
              <span className="banner-heading">
                <FormattedHTMLMessage id="bannerHeading" />
              </span>
            </div>}
            <br/>
              {!isSINGTEL && (<div>
                <span className="banner-sub-header">
                  <FormattedHTMLMessage id="bannerSubHeader" />
                </span>
              </div>)}
              {!isSINGTEL && !isTre && (<button className="live-chat-btn" id="startChat">
                {intl.formatMessage({ id: "liveChatBtn" })}
                <svg width="14" height="14" viewBox="0 0 7 14">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.284 14L0 12.716 5.716 7 0 1.284 1.284 0l7 7-7 7z"
                    fill="white"
                  />
                </svg>
              </button>)}
              {
                isTre && (
                  <div>
                  <div>
                    <a
                      type="button"
                      id="navToPortal"
                      data-track-btn="sPortal"
                      className="portal-btn sp-btn-primary btn-space visit-portal"
                      onClick={navigateToPortal}
                      href={`${process.env.GATSBY_CLAIMS_PAGE_LINK}&language=${languageParam}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {intl.formatMessage({ id: "portalBtn" })}
                      <svg width="14" height="14" viewBox="0 0 7 14">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.284 14L0 12.716 5.716 7 0 1.284 1.284 0l7 7-7 7z"
                          fill="black"
                        />
                      </svg>
                    </a>
                  </div>
                    <a type="button" className="faq-btn" target="_blank" rel="noreferrer" href={`${process.env.GATSBY_FAQ_LINK}${languageParam}.pdf`}>
                      {intl.formatMessage({ id: "viewFaqs" })}
                      <svg width="14" height="14" viewBox="0 0 7 14">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.284 14L0 12.716 5.716 7 0 1.284 1.284 0l7 7-7 7z"
                        />
                      </svg>
                    </a>
                  </div>
              )
              }
          </div>  
        }
          {(process.env.GATSBY_PARTNER === PARTNER_DETAILS.HOMECREDIT) &&
            <img src={providedBy} className="floatRight" alt="provided" />
          }
          {(process.env.GATSBY_PARTNER === PARTNER_DETAILS.HOMECREDIT_ID) &&
            ((languageParam === 'ID') ?
            <img src={providedByID} className="floatRight" alt="provided" /> :
            <img src={providedBy} className="floatRight" alt="provided" />)
          }
        </div>
      </section>
      {(process.env.GATSBY_PARTNER !== PARTNER_DETAILS.EIR && !isSINGTEL && !isTre) ?
      <section ref={stickyBnrWrap} className="sticky-wrap">
        <div className="portal-header main-container">
          <span className="req-service-text text-navy">
            <FormattedHTMLMessage id="reqServiceHeading" />
          </span>
          <a
            id="navToPortalSticky"
            onClick={navigateToPortal}
            className="portal-btn sp-btn-primary"
            href={`${process.env.GATSBY_CLAIMS_PAGE_LINK}&language=${languageParam}`}
            rel="noreferrer"
            target="_blank"
          >
            {intl.formatMessage({ id: "portalBtnSticky" })}
            {!isEPIC && <svg width="14" height="14" viewBox="0 0 7 14">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.284 14L0 12.716 5.716 7 0 1.284 1.284 0l7 7-7 7z"
              />
            </svg>}
          </a>
        </div>
      </section>
        : <section ref={stickyBnrWrap} className="sticky-wrap"></section>}
    </>
  );
}

export default Banner;
